<template>
  <div class="game-container">
    <GuideGame
      :cardList="cardList"
      :isGuidePage="false"
      @showCardItem="showCardItem"
    />
  </div>
</template>

<script>
import GuideGame from "@/components/Course/GamePage/GuideGame";
export default {
  data() {
    return {
      cardList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-ni.svg`),
          hoverImg: require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-ni.svg`),
          pinyin: "nǐ",
          hanzi: "你",
          english: "you",
          bgColorType: 0,
          isShowAnswer: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-wo.svg`),
          hoverImg:require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-wo.svg`),
          pinyin: "wǒ",
          hanzi: "我",
          english: "I; me",
          bgColorType: 1,
          isShowAnswer: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-ta.svg`),
          hoverImg:require(`@/assets/img/16-GAMES/G61-3-tiles/lesson-17/image-ta.svg`),
          pinyin: "tā tā",
          hanzi: "他/她",
          english: "he/she",
          bgColorType: 0,
          isShowAnswer: false,
        },
      ],
    };
  },
  components: {
    GuideGame,
  },
  methods: {
    showCardItem(index) {
      this.cardList[index].isShowAnswer = !this.cardList[index].isShowAnswer;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











