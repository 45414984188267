<template>
  <div class="game-container">
    <SpeakingTwoSentence
      :titleInfo="titleInfo"
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      @showSecondImg="showSecondImg"
    />
  </div>
</template>

<script>
import SpeakingTwoSentence from "@/components/Course/CoursePage/SpeakingTwoSentence";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "谁？",
        pinyin: "Shéi?",
      },
      gameList: [
        {
          firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-1.svg`),
          firstPinyin: "Shéi?",
          firstHanzi: "谁?",
          pinyin: "Dōngdōng",
          hanzi: "冬冬",
          isShowSentence: false,
          isFirst: true,
        },
        {
          firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-2.svg`),
          firstPinyin: "Shéi?",
          firstHanzi: "谁?",
          pinyin: "Xiàxia",
          hanzi: "夏夏",
          isShowSentence: false,
          isFirst: true,
        },
        {
          firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-3.svg`),
          firstPinyin: "Shéi?",
          firstHanzi: "谁?",
          pinyin: "Bàba",
          hanzi: "爸爸",
          isShowSentence: false,
          isFirst: true,
        },
        {
          firstImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-0.svg`),
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-17-1/image-4.svg`),
          firstPinyin: "Shéi?",
          firstHanzi: "谁?",
          pinyin: "Māma",
          hanzi: "妈妈",
          isShowSentence: false,
          isFirst: true,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/01-Menu/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/01-Menu/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    SpeakingTwoSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
        item.isFirst = true;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
    showSecondImg(index) {
      this.gameList[index - 1].isFirst = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
